import {useEffect, useMemo, useState} from "react";
import {
  Th,
  Tr,
  Td,
  Table,
  Thead,
  Tbody,
  Tfoot,
  useToast,
  HStack,
  TableContainer,
  Box,
} from "@chakra-ui/react";
import fieldValueFactory from "../helpers/Billing/fieldValueFactory";
import {stringifyValue} from "../helpers/stringifyValue";
import {CustomFields} from "./CustomFields";
import {GroupedTransactionExporter} from "./GroupedTransactionExporter";
import PreviewFile from "../Preview/PreviewFile";
import InvoiceOrReceipt from "../InvoiceOrReceipt";

const TransactionGrouper = ({
  transactions,
  groupings,
  fields,
  setFields,
  dashState,
}) => {
  const [, setSelectedTr] = useState(null);
  const [preview, setPreview] = useState(null);
  const [fetchingFileData, setFetchingFileData] = useState(false);
  const toast = useToast();
  const aggregatedFields = [
    "Patient Amount",
    "TPP Amount",
    "Patient Amount Paid",
    "Insurer Amount Paid",
    "Patient Outstanding Balance",
    "Insurer Outstanding Balance",
    "Tax Cost to Patient",
    "Tax Cost to Insurer",
    "Total Tax Cost",
    "Patient Amount",
    "TPP Amount",
    "Total Amount Paid",
    "Total Outstanding Balance",
    "Cost to Patient (before tax)",
    "Cost to Insurer (before tax)",
  ];
  const transactionMap = useMemo(() => {
    return transactions.reduce((acc, transaction) => {
      if (transaction.tid) {
        acc[transaction.tid] = transaction;
      } else {
        acc[transaction._id] = transaction;
      }
      return acc;
    }, {});
  }, [transactions]);
  const groupedTransactions = useMemo(() => {
    return transactions.reduce(
      (acc, transaction) => {
        let currentPath = acc;
        const patientTaxCost =
          parseFloat(transaction.patientAmount || 0) -
          fieldValueFactory["Transaction Amounts"][
            "Cost to Patient (before tax)"
          ](transaction).uiValue;
        const tppTaxCost =
          parseFloat(transaction.tppAmount || 0) -
          fieldValueFactory["Transaction Amounts"][
            "Cost to Insurer (before tax)"
          ](transaction).uiValue;
        transaction.patientTaxCost = patientTaxCost;
        transaction.tppTaxCost = tppTaxCost;
        const aggregateLevel = (group, transaction) => {
          const isCancelled =
            transaction.status?.toLowerCase() === "cancelled" ||
            transaction.isCancelled === true ||
            transaction.cancelled === true;

          if (!isCancelled) {
            group.aggregatedData.patientAmount += parseFloat(
              transaction.patientAmount || 0
            );
            group.aggregatedData.tppAmount += parseFloat(
              transaction.tppAmount || 0
            );
            group.aggregatedData.amountPaidByPatient += parseFloat(
              transaction.amountPaidByPatient || 0
            );
            group.aggregatedData.amountPaidByTpp += parseFloat(
              transaction.amountPaidByTpp || 0
            );
            if (!isNaN(patientTaxCost)) {
              group.aggregatedData.patientTaxCost += parseFloat(patientTaxCost);
            }
            if (!isNaN(tppTaxCost)) {
              group.aggregatedData.tppTaxCost += parseFloat(tppTaxCost);
            }
          }
        };
        aggregateLevel(acc, transaction);
        groupings.forEach((grouping, groupingIndex) => {
          const groupingKey = stringifyValue(
            grouping.field
              .split(".")
              .reduce((o, k) => (o ? o[k] : ""), transaction),
            groupings,
            grouping.label
          );

          if (currentPath.groups[groupingKey]) {
            currentPath.groups[groupingKey].transactionIds.push(
              transaction.tid || transaction._id
            );
          } else {
            currentPath.groups[groupingKey] = {
              transactionIds: [transaction.tid || transaction._id],
              aggregatedData: {
                patientAmount: 0,
                tppAmount: 0,
                amountPaidByPatient: 0,
                amountPaidByTpp: 0,
                patientTaxCost: 0,
                tppTaxCost: 0,
              },
              groups: {},
              path: [...currentPath.path, groupingKey],
            };
          }
          aggregateLevel(currentPath.groups[groupingKey], transaction);
          currentPath = currentPath.groups[groupingKey];
        });
        return acc;
      },
      {
        aggregatedData: {
          patientAmount: 0,
          tppAmount: 0,
          amountPaidByPatient: 0,
          amountPaidByTpp: 0,
          patientTaxCost: 0,
          tppTaxCost: 0,
        },
        groups: {},
        path: [],
      }
    );
  }, [transactions, groupings]);
  const groupKeys = Object.keys(groupedTransactions.groups);
  const filteredFieldEntries = Object.entries(fields).filter(
    ([key, fieldFunction]) => !aggregatedFields.includes(key)
  );
  return (
    <Box ml="5" mr="20" h="full" flex={1}>
      {preview && <PreviewFile preview={preview} setPreview={setPreview} />}
      <GroupedTableActions
        fields={fields}
        setFields={setFields}
        groupedTransactions={groupedTransactions}
        dashState={dashState}
        groupings={groupings}
        aggregatedFields={aggregatedFields}
        transactionMap={transactionMap}
      />
      <TableContainer
        borderRadius="md"
        boxShadow="md"
        minH="480px"
        h="full"
        maxH="720px"
        overflowY={"scroll"}
        className="sbar3"
      >
        <Table variant="simple">
          <Thead position="sticky" top="0" bg="gray.100" zIndex={5}>
            <Tr>
              {groupings.map((grouping) => (
                <Th key={grouping.label} fontWeight="bold">
                  {grouping.label}
                </Th>
              ))}
              <Th key="invoiceId" fontWeight="normal">
                Invoice ID
              </Th>
              <Th fontWeight="normal">Total balance</Th>
              <Th fontWeight="normal">Total paid</Th>
              <Th fontWeight="normal">Total amount</Th>
              {fields["Patient Amount"] && (
                <Th fontWeight="normal">Patient amount</Th>
              )}
              {fields["Patient Amount Paid"] && (
                <Th fontWeight="normal">Paid by patient</Th>
              )}
              {fields["Patient Outstanding Balance"] && (
                <Th fontWeight="normal">Patient balance</Th>
              )}
              {fields["TPP Amount"] && <Th fontWeight="normal">TPP amount</Th>}
              {fields["Insurer Amount Paid"] && (
                <Th fontWeight="normal">Paid by TPP</Th>
              )}
              {fields["Insurer Outstanding Balance"] && (
                <Th fontWeight="normal">TPP balance</Th>
              )}
              {fields["Tax Cost to Patient"] && (
                <Th fontWeight="normal">Patient tax cost</Th>
              )}
              {fields["Tax Cost to Insurer"] && (
                <Th fontWeight="normal">TPP tax cost</Th>
              )}

              {filteredFieldEntries.map(([displayKey]) => {
                return (
                  <Th key={displayKey} fontWeight="normal">
                    {displayKey}
                  </Th>
                );
              })}
              <Th fontWeight="normal">Invoice/Receipt</Th>
            </Tr>
          </Thead>
          <Tbody>
            {groupKeys.map((groupKey) => (
              <RenderGroup
                group={groupedTransactions.groups[groupKey]}
                transactionMap={transactionMap}
                groupings={groupings}
                fields={fields}
                dashState={dashState}
                setPreview={setPreview}
                fetchingFileData={fetchingFileData}
                setFetchingFileData={setFetchingFileData}
                setSelectedTr={setSelectedTr}
                toast={toast}
                filteredFieldEntries={filteredFieldEntries}
              />
            ))}
          </Tbody>
          <Tfoot position="sticky" zIndex="5" bottom="0" bg="gray.100">
            <Tr>
              {Array.from({length: groupings.length}).map((_, index) => (
                <Th key={`${index}-filler`}></Th>
              ))}
              <Th>Totals</Th>
              <Th isNumeric>
                {`$${(
                  groupedTransactions.aggregatedData.patientAmount -
                  groupedTransactions.aggregatedData.amountPaidByPatient +
                  groupedTransactions.aggregatedData.tppAmount -
                  groupedTransactions.aggregatedData.amountPaidByTpp
                ).toFixed(2)}`}
              </Th>
              <Th isNumeric>
                {`$${(
                  groupedTransactions.aggregatedData.amountPaidByPatient +
                  groupedTransactions.aggregatedData.amountPaidByTpp
                ).toFixed(2)}`}
              </Th>
              <Th isNumeric>
                {`$${(
                  groupedTransactions.aggregatedData.patientAmount +
                  groupedTransactions.aggregatedData.tppAmount
                ).toFixed(2)}`}
              </Th>
              {fields["Patient Amount"] && (
                <Th isNumeric>
                  {`$${groupedTransactions.aggregatedData.patientAmount.toFixed(
                    2
                  )}`}
                </Th>
              )}
              {fields["Patient Amount Paid"] && (
                <Th isNumeric>
                  {`$${groupedTransactions.aggregatedData.amountPaidByPatient.toFixed(
                    2
                  )}`}
                </Th>
              )}
              {fields["Patient Outstanding Balance"] && (
                <Th isNumeric>
                  {`$${(
                    groupedTransactions.aggregatedData.patientAmount -
                    groupedTransactions.aggregatedData.amountPaidByPatient
                  ).toFixed(2)}`}
                </Th>
              )}
              {fields["TPP Amount"] && (
                <Th isNumeric>
                  {`$${groupedTransactions.aggregatedData.tppAmount.toFixed(
                    2
                  )}`}
                </Th>
              )}
              {fields["Insurer Amount Paid"] && (
                <Th isNumeric>
                  {`$${groupedTransactions.aggregatedData.amountPaidByTpp.toFixed(
                    2
                  )}`}
                </Th>
              )}
              {fields["Insurer Outstanding Balance"] && (
                <Th isNumeric>
                  {`$${(
                    groupedTransactions.aggregatedData.tppAmount -
                    groupedTransactions.aggregatedData.amountPaidByTpp
                  ).toFixed(2)}`}
                </Th>
              )}
              {fields["Tax Cost to Patient"] && (
                <Th isNumeric>
                  {`$${groupedTransactions.aggregatedData.patientTaxCost.toFixed(
                    2
                  )}`}
                </Th>
              )}
              {fields["Tax Cost to Insurer"] && (
                <Th isNumeric>
                  {`$${groupedTransactions.aggregatedData.tppTaxCost.toFixed(
                    2
                  )}`}
                </Th>
              )}

              <Th colSpan={Object.keys(fields).length + 1}></Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </Box>
  );
};

const getGroupingValue = (grouping, transaction) => {
  return grouping.field
    .split(".")
    .reduce((o, k) => (o ? o[k] : ""), transaction);
};

const RenderGroup = ({
  group,
  transactionMap,
  groupings,
  fields,
  dashState,
  setPreview,
  fetchingFileData,
  setFetchingFileData,
  setSelectedTr,
  toast,
  hidden,
  filteredFieldEntries,
}) => {
  const [showChildGroups, setShowChildGroups] = useState(false);
  useEffect(() => {
    setShowChildGroups(false);
  }, [groupings]);
  const hasChildGroups = Object.keys(group.groups).length > 0;
  const groupKey = group.path.join("-");
  const groupDepth = group.path.length;
  const groupColor = `gray.${Math.max(5 - groupDepth, 1) * 100}`;
  const groupDepthDifference = groupings.length + 1 - groupDepth;
  const sortedTransactionIds = useMemo(() => {
    return group.transactionIds.sort((a, b) => {
      const aData = transactionMap[a];
      const bData = transactionMap[b];

      if (!aData || !bData) return 0;

      const aStatus = fields.Status(aData).uiValue;
      const bStatus = fields.Status(bData).uiValue;

      const statusOrder = [
        "Overdue",
        "Partially Paid",
        "Fully Paid",
        "Sent to Clinic Aid",
        "Due at Service",
        "Cancelled",
      ];

      const aStatusIndex = statusOrder.indexOf(aStatus);
      const bStatusIndex = statusOrder.indexOf(bStatus);

      if (aStatusIndex !== bStatusIndex) {
        return aStatusIndex - bStatusIndex;
      }

      return new Date(aData.serviceDate) - new Date(bData.serviceDate);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group.transactionIds, transactionMap]);
  if (hidden) return null;
  return (
    <>
      <Tr
        key={groupKey}
        bgColor={groupColor}
        cursor="pointer"
        onClick={() => setShowChildGroups(!showChildGroups)}
      >
        {group.path.map((grouping) => {
          let groupingValue = grouping;
          if (grouping === ", ") groupingValue = "N/A";
          return (
            <Td key={grouping} fontWeight="bold">
              {groupingValue}
            </Td>
          );
        })}
        {Array.from({length: groupDepthDifference}).map((_, index) => (
          <Td key={`${groupKey}-${index}-filler`}></Td>
        ))}
        <Td isNumeric>
          {`$${(
            group.aggregatedData.patientAmount -
            group.aggregatedData.amountPaidByPatient +
            group.aggregatedData.tppAmount -
            group.aggregatedData.amountPaidByTpp
          ).toFixed(2)}`}
        </Td>
        <Td isNumeric>
          {`$${(
            group.aggregatedData.amountPaidByPatient +
            group.aggregatedData.amountPaidByTpp
          ).toFixed(2)}`}
        </Td>
        <Td isNumeric>
          {`$${(
            group.aggregatedData.patientAmount + group.aggregatedData.tppAmount
          ).toFixed(2)}`}
        </Td>
        {fields["Patient Amount"] && (
          <Td isNumeric>
            {`$${group.aggregatedData.patientAmount.toFixed(2)}`}
          </Td>
        )}
        {fields["Patient Amount Paid"] && (
          <Td isNumeric>
            {`$${group.aggregatedData.amountPaidByPatient.toFixed(2)}`}
          </Td>
        )}
        {fields["Patient Outstanding Balance"] && (
          <Td isNumeric>
            {`$${(
              group.aggregatedData.patientAmount -
              group.aggregatedData.amountPaidByPatient
            ).toFixed(2)}`}
          </Td>
        )}
        {fields["TPP Amount"] && (
          <Td isNumeric>{`$${group.aggregatedData.tppAmount.toFixed(2)}`}</Td>
        )}
        {fields["Insurer Amount Paid"] && (
          <Td isNumeric>
            {`$${group.aggregatedData.amountPaidByTpp.toFixed(2)}`}
          </Td>
        )}
        {fields["Insurer Outstanding Balance"] && (
          <Td isNumeric>
            {`$${(
              group.aggregatedData.tppAmount -
              group.aggregatedData.amountPaidByTpp
            ).toFixed(2)}`}
          </Td>
        )}
        {fields["Tax Cost to Patient"] && (
          <Td isNumeric>
            {`$${group.aggregatedData.patientTaxCost.toFixed(2)}`}
          </Td>
        )}
        {fields["Tax Cost to Insurer"] && (
          <Td isNumeric>{`$${group.aggregatedData.tppTaxCost.toFixed(2)}`}</Td>
        )}

        <Td colSpan={Object.keys(fields).length + 1}></Td>
      </Tr>
      {hasChildGroups
        ? Object.keys(group.groups).map((childKey) => (
            <RenderGroup
              key={childKey}
              group={group.groups[childKey]}
              transactionMap={transactionMap}
              groupings={groupings}
              fields={fields}
              dashState={dashState}
              setPreview={setPreview}
              fetchingFileData={fetchingFileData}
              setFetchingFileData={setFetchingFileData}
              setSelectedTr={setSelectedTr}
              toast={toast}
              hidden={!showChildGroups}
              filteredFieldEntries={filteredFieldEntries}
            />
          ))
        : showChildGroups
        ? sortedTransactionIds.map((transactionId) => {
            const transactionData = transactionMap[transactionId];

            if (!transactionData) return null;

            const patientAmount = parseFloat(
              transactionData.patientAmount || 0
            );
            const amountPaidByPatient = parseFloat(
              transactionData.amountPaidByPatient || 0
            );
            const tppAmount = parseFloat(transactionData.tppAmount || 0);
            const amountPaidByTpp = parseFloat(
              transactionData.amountPaidByTpp || 0
            );
            const patientBalance = patientAmount - amountPaidByPatient;
            const tppBalance = tppAmount - amountPaidByTpp;
            const totalBalance = patientBalance + tppBalance;
            const totalPaid = amountPaidByPatient + amountPaidByTpp;
            const patientTaxCost = parseFloat(
              transactionData.patientTaxCost || 0
            );
            const tppTaxCost = parseFloat(transactionData.tppTaxCost || 0);
            return (
              <Tr key={transactionId}>
                {groupings.map((grouping) => {
                  let groupingValue = getGroupingValue(
                    grouping,
                    transactionData
                  );
                  if (groupingValue === ", " || !groupingValue)
                    groupingValue = "N/A";
                  return (
                    <Td key={grouping.label} fontWeight="bold">
                      {groupingValue}
                    </Td>
                  );
                })}
                {Array.from({length: groupDepthDifference - 1}).map(
                  (_, index) => (
                    <Td key={`${transactionId}-${index}-filler`}></Td>
                  )
                )}
                <Td key={transactionId}>{transactionData.invoiceId}</Td>{" "}
                <Td isNumeric>{`$${totalBalance.toFixed(2)}`}</Td>
                <Td isNumeric>{`$${totalPaid.toFixed(2)}`}</Td>
                <Td isNumeric>{`$${(patientAmount + tppAmount).toFixed(
                  2
                )}`}</Td>
                {fields["Patient Amount"] && (
                  <Td isNumeric>{`$${patientAmount.toFixed(2)}`}</Td>
                )}
                {fields["Patient Amount Paid"] && (
                  <Td isNumeric>{`$${amountPaidByPatient.toFixed(2)}`}</Td>
                )}
                {fields["Patient Outstanding Balance"] && (
                  <Td isNumeric>{`$${patientBalance.toFixed(2)}`}</Td>
                )}
                {fields["TPP Amount"] && (
                  <Td isNumeric>{`$${tppAmount.toFixed(2)}`}</Td>
                )}
                {fields["Insurer Amount Paid"] && (
                  <Td isNumeric>{`$${amountPaidByTpp.toFixed(2)}`}</Td>
                )}
                {fields["Insurer Outstanding Balance"] && (
                  <Td isNumeric>{`$${tppBalance.toFixed(2)}`}</Td>
                )}
                {fields["Tax Cost to Patient"] && (
                  <Td isNumeric>{`$${patientTaxCost.toFixed(2)}`}</Td>
                )}
                {fields["Tax Cost to Insurer"] && (
                  <Td isNumeric>{`$${tppTaxCost.toFixed(2)}`}</Td>
                )}
                {filteredFieldEntries.map(([displayKey, fieldFunction]) => {
                  return (
                    <Td key={displayKey}>
                      {fieldFunction(transactionData).uiValue}
                    </Td>
                  );
                })}
                <Td fontSize="15px" gap={2}>
                  <InvoiceOrReceipt
                    {...{
                      transaction: transactionData,
                      dashState,
                      setPreview,
                      fetchingFileData,
                      setFetchingFileData,
                      setSelectedTr,
                      toast,
                    }}
                  />
                </Td>
              </Tr>
            );
          })
        : null}
    </>
  );
};

const GroupedTableActions = ({
  fields,
  setFields,
  groupedTransactions,
  dashState,
  groupings,
  aggregatedFields,
  transactionMap,
}) => {
  return (
    <HStack mb={2} justifyContent={"space-between"}>
      <CustomFields fields={fields} setFields={setFields} />
      <GroupedTransactionExporter
        dashState={dashState}
        groupings={groupings}
        fields={fields}
        aggregatedFields={aggregatedFields}
        transactionMap={transactionMap}
        groupedTransactions={groupedTransactions}
      />
    </HStack>
  );
};
export default TransactionGrouper;
