import {useState} from "react";
import {
  Flex,
  Text,
  Button,
  Box,
  Stack,
  RadioGroup,
  Radio,
  ButtonGroup,
  CloseButton,
  useToast,
} from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import dataFields from "../helpers/Billing/dataFields";
import {fetchTransactions} from "../helpers/Billing/fetchTransaction";

export default function GroupingFields({
  groupings,
  setGroupings,
  setTransactions,
  patient,
  dashState,
}) {
  const toast = useToast();
  const [selectGroup, setSelectGroup] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {isOpen, onOpen, onClose} = useDisclosure();

  const handleApplyClick = () => {
    setIsLoading(true);
    selectGroup && setGroupings([...groupings, JSON.parse(selectGroup)]);
    fetchTransactions(
      dashState,
      null,
      null,
      setTransactions,
      setIsLoading,
      toast,
      patient
    );
    setSelectGroup("");
    onClose();
  };

  return (
    <>
      <Flex overflowWrap={"anywhere"}>
        <Text
          mr="1"
          mt="2.5"
          fontWeight="semibold"
          color="blue.600"
          fontSize="15px"
        >
          Group By:{" "}
        </Text>
        {groupings.map((group, i) => (
          <Box
            display="inline-flex"
            alignItems="center"
            rounded="md"
            p="1.5"
            position="relative"
          >
            <Button colorScheme="blue" variant="outline" size="sm" bg="blue.50">
              {group?.label}
              <CloseButton
                size="sm"
                ml="2"
                mr="-2"
                color="blue.600"
                onClick={() =>
                  setGroupings((prevGroupings) =>
                    prevGroupings.filter((_, idx) => idx !== i)
                  )
                }
              />
            </Button>
          </Box>
        ))}
        <Button
          colorScheme="blue"
          variant="outline"
          m="1.5"
          px="6"
          size="sm"
          ml="3"
          onClick={onOpen}
        >
          Choose {groupings.length > 0 && "Another"} Field
        </Button>
      </Flex>

      <Modal
        isOpen={isOpen}
        size={"xs"}
        onClose={onClose}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pt={4} fontWeight="bold" border="0">
            Group By Field
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack direction="row" spacing={8}>
              {Object.entries(dataFields).map(([category, fields]) => (
                <Box key={category}>
                  <Text fontSize="sm" fontWeight="bold" mb={2} color="gray.700">
                    {category}
                  </Text>
                  <RadioGroup onChange={setSelectGroup} value={selectGroup}>
                    {Object.entries(fields).map(([key, value]) => (
                      <>
                        <Radio
                          key={key}
                          value={JSON.stringify({label: key, field: value})}
                          size="sm"
                        >
                          {key}
                        </Radio>
                        <br />
                      </>
                    ))}
                  </RadioGroup>
                </Box>
              ))}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex justifyContent="end">
              <ButtonGroup size="sm">
                <Button colorScheme="blue" variant="ghost" onClick={onClose}>
                  Go Back
                </Button>
                <Button
                  isLoading={isLoading}
                  colorScheme="blue"
                  onClick={handleApplyClick}
                >
                  Apply
                </Button>
              </ButtonGroup>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
