import {v4 as uuidv4} from "uuid";
import Swal from "sweetalert2";
import {
  addMonths,
  startOfMonth,
  subMonths,
  addWeeks,
  subWeeks,
  startOfYear,
  subYears,
  startOfToday,
} from "date-fns";
import fieldValueFactory from "./fieldValueFactory";
import {crud} from "../../../crudRequests";
export async function fetchBillingQueries(state, toast, setBillingQueries) {
  try {
    const res = await crud(state, [
      {
        db: state.db,
        collection: "billingQueries",
        parameters: [{}],
        method: "find",
      },
    ]);
    let data = res.data?.[0] || [];
    setBillingQueries(data.map(dayRageFormatQuery));
  } catch (err) {
    console.log(err);
    toast({
      title: "Failed to fetch queries data",
      description:
        err?.message || "An error occurred while fetching queries data.",
      status: "error",
      isClosable: true,
    });
  }
}

export async function updateBillingQuery(
  state,
  toast,
  query,
  setBillingQueries,
  setLoading
) {
  try {
    setLoading(true);
    let {_id, data} = query;
    const res = await crud(state, [
      {
        db: state.db,
        collection: "billingQueries",
        parameters: [{qid: query.qid}, {$set: data}],
        method: "updateOne",
      },
    ]);
    setBillingQueries((prev) =>
      prev.map((q) => (q.qid === query.qid ? query : q))
    );
    setLoading(false);
  } catch (err) {
    setLoading(false);
    console.log(err);
    toast({
      title: "Failed to update query data",
      description:
        err?.message || "An error occurred while updating query data.",
      status: "error",
      isClosable: true,
    });
  }
}

export async function deleteBillingQuery(
  state,
  toast,
  query,
  setBillingQueries,
  setLoading
) {
  try {
    Swal.fire({
      title: "Delete Query?",
      text: "Are you sure you want to delete this query?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Back`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const res = await crud(state, [
          {
            db: state.db,
            collection: "billingQueries",
            parameters: [{qid: query.qid}],
            method: "deleteOne",
          },
        ]);
        setBillingQueries((prev) => prev.filter((q) => q.qid !== query.qid));
        setLoading(false);
      }
    });
  } catch (err) {
    setLoading(false);
    console.log(err);
    toast({
      title: "Failed to dalete query data",
      description:
        err?.message || "An error occurred while deleting query data.",
      status: "error",
      isClosable: true,
    });
  }
}
export async function newBillingQuery(
  state,
  toast,
  query,
  setQuery,
  setBillingQueries,
  setLoading,
  action
) {
  try {
    const qid = uuidv4();
    setLoading(true);
    const res = await crud(state, [
      {
        db: state.db,
        collection: "billingQueries",
        parameters: [{...query, qid}],
        method: "insertOne",
      },
    ]);
    setBillingQueries((prev) => [{...query, qid}].concat(prev));
    setQuery({...query, qid});
    setLoading(false);
    action();
    toast({
      title: "The query was saved successfully",
      duration: 3000,
      status: "success",
      isClosable: true,
    });
  } catch (err) {
    setLoading(false);
    console.log(err);
    toast({
      title: "Failed to create query data",
      description:
        err?.message || "An error occurred while creating query data.",
      status: "error",
      isClosable: true,
    });
  }
}

export function queryFields(query) {
  let f = query.fields || [];
  return f.reduce((ac, ele) => {
    for (let v of Object.values(fieldValueFactory)) {
      if (v[ele]) {
        ac[ele] = v[ele];
        break;
      }
    }

    return ac;
  }, {});
}

export function dayRageFormatQuery(query) {
  if (query?.filters?.daysRange) {
    switch (query?.filters?.daysRange) {
      case "NEXT_3_MONTHS": {
        let m = startOfMonth(new Date());
        return {
          ...query,
          filters: {
            ...query?.filters,
            fromDate: m.getTime(),
            toDate: addMonths(m, 3).getTime(),
            daysRange: "NEXT_3_MONTHS",
          },
        };
      }

      case "NEXT_4_WEEKS": {
        let d = startOfToday();
        return {
          ...query,
          filters: {
            ...query?.filters,
            fromDate: d.getTime(),
            toDate: addWeeks(d, 4).getTime(),
            daysRange: "NEXT_4_WEEKS",
          },
        };
      }

      case "LAST_4_WEEKS": {
        let d = startOfToday();

        return {
          ...query,
          filters: {
            ...query?.filters,
            fromDate: subWeeks(d, 4).getTime(),
            toDate: d.getTime(),
            daysRange: "LAST_4_WEEKS",
          },
        };
      }

      case "LAST_3_MONTHS": {
        let m = startOfMonth(new Date());
        return {
          ...query,
          filters: {
            ...query?.filters,
            fromDate: subMonths(m, 3).getTime(),
            toDate: m.getTime(),
            daysRange: "LAST_3_MONTHS",
          },
        };
      }

      case "LAST_6_MONTHS": {
        let m = startOfMonth(new Date());
        return {
          ...query,
          filters: {
            ...query?.filters,
            fromDate: subMonths(m, 6).getTime(),
            toDate: m.getTime(),
            daysRange: "LAST_6_MONTHS",
          },
        };
      }

      case "LAST_YEAR": {
        let m = startOfYear(new Date());
        return {
          ...query,
          filters: {
            ...query?.filters,
            fromDate: subYears(m, 1).getTime(),
            toDate: m.getTime(),
            daysRange: "LAST_YEAR",
          },
        };
      }
      default:
        return query;
    }
  }
  return query;
}
