import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import Dashboard from "./pages/Dashboard";
import Inbox from "./pages/Inbox";
import User from "./pages/User";
import Forms from "./pages/Forms";
import Journal from "./pages/Journal";
import Chatbot from "./pages/Chatbot";
import Login from "./pages/Login";
import Register from "./pages/Register";
import SleepTracker from "./pages/SleepTracker";
import CardInfoFormLink from "./pages/CardInfoFormLink";
import Payments from "./pages/Payments";
import Intake from "./pages/Intake";
import {ChakraProvider} from "@chakra-ui/react";
import SleepDiary from "./components/SleepDiary/SleepDiary";
import PrescriptionCompliance from "./components/PrescriptionCompliance/PrescriptionCompliance";
import TempForm from "./components/TempForm";
import {Billing} from "./pages/Billing";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ChakraProvider>
      <Routes>
        <Route path="/login/:db" element={<Login />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/cardInfo" element={<CardInfoFormLink />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/" element={<Dashboard />}></Route>
        <Route path="/inbox" element={<Inbox />}></Route>
        <Route path="/user" element={<User />}></Route>
        <Route path="/forms" element={<Forms />}></Route>
        <Route path="/journal" element={<Journal />}></Route>
        <Route path="/chatbot" element={<Chatbot />}></Route>
        <Route path="/intake" element={<Intake />}></Route>
        <Route path="/sleepTracker" element={<SleepTracker />}></Route>
        <Route path="/sleep" element={<SleepDiary />}></Route>
        <Route
          path="/prescriptionCompliance"
          element={<PrescriptionCompliance />}
        ></Route>
        <Route path="/payments" element={<Payments />}></Route>
        <Route path="/billing" element={<Billing />}></Route>
        <Route path="/temp-form" element={<TempForm />}></Route>
      </Routes>
    </ChakraProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
