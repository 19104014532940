import axios from "axios";
import {nanoid} from "nanoid";
import {v4 as uuidv4} from "uuid";
import {pdf} from "@react-pdf/renderer";
import InvRecpDF from "./components/Invoice";
import {crudStorageUpload} from "./storageRequests";
import {lightFormat} from "date-fns";
import {notificationsData} from "./additional_files/notifications";
import {wait} from "@testing-library/user-event/dist/utils";
// import {getHash} from "./components/helpers/jitsiFunctions";
export const url = window.location.href.includes("localhost")
  ? "http://localhost:3000"
  : "https://blh-server-2001610adcd9.herokuapp.com";

export function jwtDecode(t) {
  try {
    return JSON.parse(window.atob(t.split(".")[1]));
  } catch {
    window.location.href = "../login";
  }
}

export async function supplementaryCrud(state, requestObjs) {
  return axios({
    method: "POST",
    url: `${url}/supplementaryCrud`,
    headers: {
      "Content-Type": "application/json",
      //Authorization: "Bearer " + state?.jwt || "",
    },
    data: JSON.stringify({
      //id: state?.jwt ? jwtDecode(state.jwt).id : "",
      requestObjs: requestObjs,
    }),
  })
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      console.log("client error", error.message);
      //if (error.code !== "ERR_CANCELED") window.location.href = "../login";
    });
}

export async function crud(state, requestObjs, options, signal) {
  let configService = {
    method: "POST",
    url: `${url}/crud`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state?.jwt || "",
    },
    data: JSON.stringify({
      id: state?.jwt ? jwtDecode(state.jwt).id : "",
      requestObjs: requestObjs,
      options,
    }),
    signal,
  };
  return axios(configService).then((res) => {
    return res;
  });
}

export async function verifyBookNow(state) {
  try {
    if (state.patient) {
      let patient = state.patient;

      let requestObjs = [
        {
          db: state.db,
          collection: "bookNow",
          parameters: [{pid: state.patient?.pid}],
          method: "deleteMany",
        },
        {
          db: state.db,
          collection: "services",
          parameters: [{serviceOid: state.patient?.oid}],
          method: "find",
        },
      ];
      let bkAppt = await crud(
        state,
        [
          {
            db: state.db,
            collection: "bookNow",
            parameters: [{pid: state.patient?.pid}],
            method: "findOne",
          },
          {
            db: state.db,
            collection: "doctors",
            parameters: [{did: state.patient.lid?.[0]}],
            method: "findOne",
          },
        ],
        null
      );
      bkAppt = bkAppt.data;
      await crud(state, requestObjs, null);

      //console.log(bkAppt);
      if (bkAppt?.[0]) {
        let payload = bkAppt[0];

        let roomName = "";
        let ptUrl = "";

        let doc = await crud(
          state,
          [
            {
              db: state.db,
              collection: "doctors",
              parameters: [{did: payload.did}],
              method: "findOne",
            },
          ],
          null
        );
        doc = doc.data?.[0];

        if (doc) {
          // try {
          //   const res = await getHash(
          //     state,
          //     payload.aid,
          //     patient.pid,
          //     state.db,
          //     doc
          //   );
          //   roomName = res?.roomName;
          //   ptUrl = res?.ptUrl;
          // } catch (error) {
          //   console.log(error);
          // }
          const roomName = payload.aid;
          let patientApptLink = `https://teleconference.brightlight.ai/${roomName}`;
          let clinicApptLink = `https://teleconference.brightlight.ai/${roomName}`;

          /*console.log(url);
          console.log(`${url}/appt?hash=${ptUrl}`);
          console.log(`https://teleconference.brightlight.ai/${roomName}`);*/

          payload = {...payload, patientApptLink, clinicApptLink};

          let transaction = {
            tid: uuidv4(),
            invoiceId: payload.invoiceId,
            receiptId: payload.receiptId,
            aid: payload.aid,
            oid: payload.oid,
            pid: payload.pid,
            lid: payload.lid,
            pName: payload.pName,
            pEmail: payload.pName,
            did: payload.did,
            dName: payload.dName,
            dEmail: payload.dEmail,
            time: payload.duration,
            supervisorId: payload.supervisorId,
            supervisorName: payload.supervisorName,
            serviceId: payload.serviceId,
            serviceType: payload.service.trim(),
            serviceDate: payload.ISOdate,
            insurance: state.patient.insurance?.[0] || "",
            dgCodes: [],
            billingCodes: [],
            amount: payload.amount,
            paid: 0,
            pending: true,
            tpp: payload.tpp,
            tppAmount: payload.tppAmount,
            patientAmount: payload.patientAmount,
            amountPaidByPatient: payload.amountPaidByPatient,
            amountPaidByTpp: payload.amountPaidByTpp,
            tppPaymentStatus: payload.tppPaymentStatus,
            patientPaymentStatus: payload.patientPaymentStatus,
            assignedProfessional: payload.assignedProfessional || "clinician",
          };

          let ntf = notificationsData("patient", "New appointment", payload);
          let tpp = payload?.tpp;

          let requestO = [
            {
              db: state.db,
              collection: "appointments",
              parameters: [payload],
              method: "insertOne",
            },
            {
              db: state.db,
              collection: "notifications",
              parameters: [
                {userType: "doctor"},
                {$push: {[payload.did]: ntf}},
                {upsert: true},
              ],
              method: "findOneAndUpdate",
            },
            {
              db: state.db,
              collection: "notifications",
              parameters: [
                {userType: "admin"},
                {$push: {[payload.lid + payload.oid]: ntf}},
                {upsert: true},
              ],
              method: "findOneAndUpdate",
            },
            {
              db: state.db,
              collection: "billing",
              parameters: [transaction],
              method: "insertOne",
            },
          ];

          let emailOption = {type: "new_appointment", content: payload};
          let rooms = [payload.did, payload.lid + payload.oid];

          await crud(state, requestO, {
            email: emailOption,
            verifyCard: {pid: payload.pid, db: state.db},
            apptReminderNotification: {appt: payload},
            socket: {
              rooms,
              arguments: [
                "new_appt",
                payload,
                {ntf, newPatient: state.patient},
              ],
            },
          });

          let res = await crud(state, [
            {
              db: state.db,
              collection: "doctors",
              parameters: [{did: payload.did}],
              method: "findOne",
            },
            {
              db: state.db,
              collection: "doctors",
              parameters: [{did: payload.supervisorId || ""}],
              method: "findOne",
            },
            {
              db: state.db,
              collection: "insurers",
              parameters: [{iid: payload?.tpp || ""}],
              method: "findOne",
            },
          ]);

          let doctor = res.data[0];
          let supervisor = res.data[1];
          let insurer = res.data[2];

          let sendInvEmail = async (
            state,
            transaction,
            insurer,
            doctor,
            supervisor
          ) => {
            try {
              let blob1 = pdf(
                <InvRecpDF
                  state={state}
                  transaction={transaction}
                  type="PATIENT_INVOICE"
                  tpp={insurer}
                  returnDoc
                  patient={patient}
                  doctor={doctor}
                  supervisor={supervisor}
                />
              ).toBlob();

              let blob2 = pdf(
                <InvRecpDF
                  state={state}
                  transaction={transaction}
                  type="FULL_INVOICE"
                  returnDoc
                  patient={patient}
                  doctor={doctor}
                  supervisor={supervisor}
                  tpp={insurer}
                />
              ).toBlob();

              let blob3 = pdf(
                <InvRecpDF
                  state={state}
                  transaction={transaction}
                  type="INSURER_INVOICE"
                  returnDoc
                  patient={patient}
                  doctor={doctor}
                  supervisor={supervisor}
                  tpp={insurer}
                />
              ).toBlob();
              let blobArray = [blob1, blob2];
              if (transaction.tpp) blobArray.push(blob3);
              let arr = await Promise.all(blobArray);
              let d = new Date();

              await crudStorageUpload(
                state,
                new File(
                  [arr[0]],
                  "prefix_" +
                    d.getTime() +
                    "-" +
                    arr[0].size / 1000 +
                    "-" +
                    "Invoice " +
                    lightFormat(d, "hh_mm aaa") +
                    ".pdf"
                ),
                "brightlighthealth",
                payload["pid"] + `/Invoices/${payload["aid"]}/Patient`
              );

              await crudStorageUpload(
                state,
                new File(
                  [arr[1]],
                  "prefix_" +
                    d.getTime() +
                    "-" +
                    arr[0].size / 1000 +
                    "-" +
                    "Consolidated_Invoice" +
                    ".pdf"
                ),
                "brightlighthealth",
                payload["pid"] + `/Invoices/${payload["aid"]}`
              );
              if (transaction.tpp)
                await crudStorageUpload(
                  state,
                  new File(
                    [arr[2]],
                    "prefix_" +
                      d.getTime() +
                      "-" +
                      arr[0].size / 1000 +
                      "-" +
                      "Invoice " +
                      lightFormat(d, "hh_mm aaa") +
                      ".pdf"
                  ),
                  "brightlighthealth",
                  payload["pid"] + `/Invoices/${payload["aid"]}/Insurer`
                );
            } catch (error) {
              throw error;
            }
          };

          const updateInsuredSessions = async () => {
            try {
              if (Array.isArray(patient?.tpp)) {
                let insr = patient?.tpp.find((ins) => ins.insurerId === tpp);

                if (insr && insr.noOfInsuredSessions) {
                  let noOfInsuredSessions =
                    parseInt(insr.noOfInsuredSessions) - 1;

                  let newPatientTpp = patient?.tpp.map((ins) =>
                    ins.insurerId === tpp ? {...ins, noOfInsuredSessions} : ins
                  );

                  await crud(
                    state,
                    [
                      {
                        db: state.db,
                        collection: "patients",
                        parameters: [
                          {pid: payload.pid, "tpp.insurerId": tpp},
                          {
                            $set: {
                              "tpp.$.noOfInsuredSessions": noOfInsuredSessions,
                            },
                          },
                        ],
                        method: "updateOne",
                      },
                    ],
                    {
                      socket: {
                        rooms,
                        arguments: [
                          "update_pt",
                          payload.pid,
                          {tpp: newPatientTpp},
                          {},
                          [payload.did, payload.lid + payload.oid, payload.pid],
                        ],
                      },
                    }
                  );
                }
              }
            } catch (error) {
              throw error;
            }
          };
          await sendInvEmail(state, transaction, insurer, doctor, supervisor);
          await updateInsuredSessions();
          return payload;
        }
        return null;
      }
      return null;
    }
    return null;
  } catch (error) {
    console.log(error.message);
    // if (error.code !== "ERR_CANCELED") window.location.href = "../login";
    return null;
  }
}

export async function loadDataForPatientLogin(state, navigate, loader, signal) {
  loader && loader("animate-spin");

  try {
    let query = {
      [state.patient?.email ? "email" : "phone"]:
        state.patient?.email || state.patient?.phone,
    };

    let requestObjs = [
      {
        db: state.db,
        collection: "patients",
        parameters: [query],
        method: "findOne",
      },
      {
        db: state.db,
        collection: "doctors",
        parameters: [{lid: state.patient.lid?.[0]}],
        method: "find",
      },
      {
        db: state.db,
        collection: "appointments",
        parameters: [{pid: state.patient?.pid}],
        method: "find",
      },
      {
        db: state.db,
        collection: "notifications",
        parameters: [
          {userType: "patient", [state.patient?.pid]: {$exists: true}},
          {[state.patient?.pid]: 1},
        ],
        method: "findOne",
      },
      {
        db: state.db,
        collection: "services",
        parameters: [{lid: state.patient.lid?.[0]}],
        method: "find",
      },
      {
        db: state.db,
        collection: "cases",
        parameters: [{pid: state.patient?.pid}],
        method: "find",
      },
      {
        db: state.db,
        collection: "locations",
        parameters: [{}, {stpSecretKey: 0}],
        method: "find",
      },
    ];

    if (state.userType === "relationship" && state.relationship) {
      requestObjs.push({
        db: state.db,
        collection: "relations",
        parameters: [{rid: state.relationship?.rid}],
        method: "findOne",
      });
    }

    let requestObjs2 = [
      {
        db: "customers",
        collection: "organizations",
        parameters: [{db: state.db}, {stpSecretKey: 0}],
        method: "findOne",
      },
    ];

    let bookNow = await verifyBookNow(state);
    let res = await crud(state, requestObjs, null, signal);
    let res2 = await crud(state, requestObjs2, null, signal);
    let data = res.data;
    let data2 = res2.data;
    loader && loader("");
    const newState = {
      ...state,
      patient: data[0],
      doctors: data[1] || [],
      appointments: data[2] || [],
      notifications: data[3] ? data[3][state.patient.pid] : [],
      services: data[4] || [],
      cases: data[5] || [],
      locations: data[6] || [],
      organization: data2[0] || [],
      selectedLocation: (data[6] || [])[0]?.lid,
      relationship: data[7],
      bookNow,
    };

    navigate(state.destination, {
      state: newState,
    });
  } catch (error) {
    console.log(error.message);
    // if (error.code !== "ERR_CANCELED") window.location.href = "../login";
  }
}

export async function viewOrganizationByUrl(u) {
  let configOrganization = {
    method: "POST",
    url: `${url}/viewOrganizationByUrl`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({url: u}),
  };
  return axios(configOrganization)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      window.location.href = "../login";
    });
}
export async function viewCustomers() {
  let config = {
    method: "GET",
    url: `${url}/viewCustomers`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config);
}
export async function viewConsentForms(db) {
  let config = {
    method: "POST",
    url: `${url}/viewConsentForms`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({db}),
  };
  return axios(config);
}

export async function getPaymentMethods(state, pid, lid = "") {
  let configService = {
    method: "POST",
    url: `${url}/getPaymentMethods`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      pid,
      db: state.db,
      lid,
    }),
  };
  return axios(configService).then((res) => {
    return res;
  });
}

export async function setDefaultPaymentMethods(state, pid, paymentId, lid) {
  let configService = {
    method: "POST",
    url: `${url}/setDefaultPaymentMethods`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      pid,
      db: state.db,
      paymentId,
      id: jwtDecode(state.jwt).id,
      lid,
    }),
  };
  return axios(configService).then((res) => {
    return res;
  });
}
export async function detachPaymentMethods(state, pid, paymentId) {
  let configService = {
    method: "POST",
    url: `${url}/detachPaymentMethods`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      pid,
      db: state.db,
      paymentId,
      id: jwtDecode(state.jwt).id,
    }),
  };
  return axios(configService).then((res) => {
    return res;
  });
}

export async function createPaymentMethod(state, pid, paymentId) {
  let configService = {
    method: "POST",
    url: `${url}/createPaymentMethod`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      pid,
      db: state.db,
      paymentId,
      id: jwtDecode(state.jwt).id,
    }),
  };
  return axios(configService).then((res) => {
    return res;
  });
}

export async function executePayment(
  state,
  content,
  pid,
  paymentMethod,
  payment_intent_id
) {
  let configService = {
    method: "POST",
    url: `${url}/executePayment`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      content,
      pid,
      paymentMethod,
      db: state.db,
      payment_intent_id,
    }),
  };
  return axios(configService);
}

export async function sendInvRecEmail(state, content) {
  let config = {
    method: "POST",
    url: `${url}/sendInvRec`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      content,
    }),
  };
  return axios(config);
}

export async function updatePreferences(state, pid, email, text) {
  let configService = {
    method: "POST",
    url: `${url}/updatePreferences`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: {
      pid,
      db: state.db,
      email,
      text,
    },
  };
  return axios(configService).then((res) => {
    return res;
  });
}

export async function excelTransactionsReport(state, table, location) {
  let configTable = {
    method: "POST",
    url: `${url}/excelTransactionsReport`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      table,
      location,
      user: state.patient || state.admin,
    }),
  };
  return axios(configTable);
}
