const dateValueKeys = new Set(["Service Date & Time", "Appointment Date/Time"]);

export const stringifyValue = (val, groupings, displayKey) => {
  if (groupings?.length > 0) {
    if (Array.isArray(val)) {
      return (
        "[" +
        val
          .map((item, index) => stringifyValue(item, groupings, displayKey))
          .join(", ") +
        "]"
      );
    } else if (val !== null && typeof val === "object") {
      const objectStrings = Object.entries(val).map(([key, value]) => {
        return `${key}: ${stringifyValue(value, groupings, displayKey)}`;
      });
      return "{" + objectStrings.join(", ") + "}";
    } else {
      return primitivesValue(val, groupings, displayKey);
    }
  } else {
    if (Array.isArray(val)) {
      return val
        .map((item) => stringifyValue(item, groupings, displayKey))
        .join(" \n");
    } else if (val !== null && typeof val === "object") {
      return Object.entries(val)
        .map(
          ([key, value], index) =>
            `${key}: ${stringifyValue(value, groupings, displayKey)}`
        )
        .join(" \n");
    } else return primitivesValue(val, groupings, displayKey);
  }
};

function primitivesValue(val, groupings, keyName) {
  if (val && keyName && dateValueKeys.has(keyName))
    return new Date(val).toLocaleString();
  if (val === null || val === undefined || val === "") {
    return "N/A";
  } else {
    return String(val);
  }
}
