import {lightFormat} from "date-fns";

export function membershipFileNameFormat(transaction) {
  const date = new Date(transaction.serviceDate);
  return (
    lightFormat(
      new Date(date.getTime() + date.getTimezoneOffset() * 60000),
      "dd-MM-yy HH-mm aaa"
    ) + ` (${transaction.tid})`
  );
}
