import {useState} from "react";
import {
  Button,
  Box,
  Stack,
  Text,
  Checkbox,
  Flex,
  ButtonGroup,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import fieldValueFactory from "../helpers/Billing/fieldValueFactory";

const defaultChecked = new Set([
  "Service Cost (including tax)",
  "Total Amount Paid",
  "Total Outstanding Balance",
  "Status",
]);

export const CustomFields = ({setFields, fields}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFields, setSelectedFields] = useState({});

  const handleApplyClick = () => {
    setIsLoading(true);
    setFields(selectedFields);
    setIsLoading(false);
    setSelectedFields({});
    onClose();
  };
  return (
    <>
      <Button
        onClick={() => {
          setSelectedFields(fields);
          onOpen();
        }}
        colorScheme="blue"
        width={"12rem"}
      >
        Add/Remove Fields
      </Button>

      <Modal
        isOpen={isOpen}
        size={"4xl"}
        onClose={() => {
          setSelectedFields({});
          onClose();
        }}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pt={4} fontWeight="bold" border="0">
            Add/Remove Fields
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack direction="row" spacing={8}>
              {Object.entries(fieldValueFactory).map(
                ([category, categoryValue], i) => (
                  <Box key={i}>
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      mb={2}
                      color="gray.700"
                    >
                      {category}
                    </Text>
                    {Object.entries(categoryValue).map(([key, value], i) => {
                      let isChecked = selectedFields.hasOwnProperty(key);
                      let disabled = defaultChecked.has(key)
                        ? {isDisabled: true, defaultChecked: true}
                        : {};
                      return (
                        <Box key={`${category}-${key}-${i}`}>
                          <Checkbox
                            {...disabled}
                            isChecked={isChecked || defaultChecked.has(key)}
                            onChange={(e) => {
                              setSelectedFields((prev) => {
                                let data = {...prev};
                                if (isChecked) {
                                  delete data[key];
                                } else {
                                  data = {
                                    ...data,
                                    [key]: value,
                                  };
                                }
                                return data;
                              });
                            }}
                            size="sm"
                          >
                            {key}
                          </Checkbox>
                          <br />
                        </Box>
                      );
                    })}
                  </Box>
                )
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex justifyContent="end">
              <ButtonGroup size="sm">
                <Button colorScheme="blue" variant="ghost" onClick={onClose}>
                  Go Back
                </Button>
                <Button
                  isLoading={isLoading}
                  colorScheme="blue"
                  onClick={handleApplyClick}
                >
                  Apply
                </Button>
              </ButtonGroup>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
