import {
  Button,
  ButtonGroup,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Box,
  CloseButton,
  Text,
  Select,
} from "@chakra-ui/react";
import {useState} from "react";
import {
  addMonths,
  startOfMonth,
  subMonths,
  addWeeks,
  subWeeks,
  startOfYear,
  subYears,
  lightFormat,
  startOfToday,
} from "date-fns";

export default function Filters({query, setQuery, useTimestamps = false}) {
  const filters = useTimestamps ? query.filters || {} : query;

  const [fromDate, setFromDate] = useState(
    useTimestamps && filters.fromDate
      ? lightFormat(filters.fromDate, "yyyy-MM-dd")
      : filters.fromDate || ""
  );

  const [toDate, setToDate] = useState(
    useTimestamps && filters.toDate
      ? lightFormat(filters.toDate, "yyyy-MM-dd")
      : filters.toDate || ""
  );

  const [fromAmount, setFromAmount] = useState(filters.fromAmount || "");
  const [toAmount, setToAmount] = useState(filters.toAmount || "");
  const [amountType, setAmountType] = useState(filters.amountType || "amount");

  const updateDateQuery = (field, value) => {
    if (useTimestamps) {
      if (field === "fromDate") {
        let dateArray = [];
        if (value) {
          dateArray = value.split("-");
          dateArray[1]--;
        }
        let inputDate = new Date(...dateArray);

        setQuery((prevQuery) => ({
          ...prevQuery,
          filters: {
            ...(prevQuery.filters || {}),
            fromDate: value ? inputDate.getTime() : null,
            daysRange: "",
          },
        }));
      } else if (field === "toDate") {
        let dateArray = [];
        if (value) {
          dateArray = value.split("-");
          dateArray[1]--;
        }
        let inputDate = new Date(...dateArray);
        inputDate.setHours(23, 59);

        setQuery((prevQuery) => ({
          ...prevQuery,
          filters: {
            ...(prevQuery.filters || {}),
            toDate: value ? inputDate.getTime() : null,
            daysRange: "",
          },
        }));
      } else {
        const fromTimestamp = fromDate ? new Date(fromDate).getTime() : null;
        const toTimestamp = toDate
          ? (() => {
              const date = new Date(toDate);
              date.setHours(23, 59);
              return date.getTime();
            })()
          : null;

        setQuery((prevQuery) => ({
          ...prevQuery,
          filters: {
            ...(prevQuery.filters || {}),
            fromDate: fromTimestamp,
            toDate: toTimestamp,
            daysRange: field === "daysRange" ? value : "",
          },
        }));
      }
    } else {
      if (field === "fromDate" || field === "toDate") {
        setQuery((prevQuery) => ({
          ...prevQuery,
          [field]: value || null,
        }));
      } else {
        setQuery((prevQuery) => ({
          ...prevQuery,
          fromDate: fromDate || null,
          toDate: toDate || null,
        }));
      }
    }
  };

  const clearDateRange = () => {
    setFromDate("");
    setToDate("");

    if (useTimestamps) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        filters: {
          ...(prevQuery.filters || {}),
          fromDate: null,
          toDate: null,
          daysRange: "",
        },
      }));
    } else {
      setQuery((prevQuery) => ({
        ...prevQuery,
        fromDate: null,
        toDate: null,
      }));
    }
  };

  const setDateRange = (daysRange, fromValue, toValue) => {
    const formattedFromDate = lightFormat(fromValue, "yyyy-MM-dd");
    const formattedToDate = lightFormat(toValue, "yyyy-MM-dd");

    setFromDate(formattedFromDate);
    setToDate(formattedToDate);

    if (useTimestamps) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        filters: {
          ...(prevQuery.filters || {}),
          fromDate: fromValue,
          toDate: toValue,
          daysRange: daysRange,
        },
      }));
    } else {
      setQuery((prevQuery) => ({
        ...prevQuery,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
      }));
    }
  };

  const updateAmountQuery = (field, value) => {
    if (useTimestamps) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        filters: {
          ...(prevQuery.filters || {}),
          [field]: value || null,
        },
      }));
    } else {
      setQuery((prevQuery) => ({
        ...prevQuery,
        [field]: value || null,
      }));
    }
  };

  const clearAmountRange = () => {
    setFromAmount("");
    setToAmount("");

    if (useTimestamps) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        filters: {
          ...(prevQuery.filters || {}),
          amountType: amountType,
          fromAmount: null,
          toAmount: null,
        },
      }));
    } else {
      setQuery((prevQuery) => ({
        ...prevQuery,
        amountType: amountType,
        fromAmount: null,
        toAmount: null,
      }));
    }
  };

  const getDateRangeLabel = (range) => {
    if (!useTimestamps) return "";
    return filters.daysRange === range ? "blue.800" : null;
  };

  return (
    <>
      <Box
        mb="4"
        py="2"
        bg="gray.50"
        rounded="xl"
        width="100%"
        overflowX="auto"
        boxShadow="xl"
      >
        <Text
          fontSize="sm"
          fontWeight="semibold"
          color="gray.700"
          px="6"
          py="2"
        >
          Filter By Date Range:
        </Text>
        <ButtonGroup size="sm" mx="3" display="flex" flexWrap="wrap" gap="2">
          <Button
            colorScheme="blue"
            variant="ghost"
            color={
              useTimestamps
                ? getDateRangeLabel("NEXT_3_MONTHS")
                : fromDate === lightFormat(startOfToday(), "yyyy-MM-dd") &&
                  toDate ===
                    lightFormat(addMonths(startOfToday(), 3), "yyyy-MM-dd")
                ? "blue.800"
                : null
            }
            onClick={() => {
              if (useTimestamps) {
                const m = startOfMonth(new Date());
                setDateRange(
                  "NEXT_3_MONTHS",
                  m.getTime(),
                  addMonths(m, 3).getTime()
                );
              } else {
                const today = startOfToday();
                setDateRange("", today, addMonths(today, 3));
              }
            }}
          >
            Next 3 months
          </Button>
          <Button
            colorScheme="blue"
            variant="ghost"
            color={
              useTimestamps
                ? getDateRangeLabel("NEXT_4_WEEKS")
                : fromDate === lightFormat(startOfToday(), "yyyy-MM-dd") &&
                  toDate ===
                    lightFormat(addWeeks(startOfToday(), 4), "yyyy-MM-dd")
                ? "blue.800"
                : null
            }
            onClick={() => {
              if (useTimestamps) {
                const d = startOfToday();
                setDateRange(
                  "NEXT_4_WEEKS",
                  d.getTime(),
                  addWeeks(d, 4).getTime()
                );
              } else {
                const today = startOfToday();
                setDateRange("", today, addWeeks(today, 4));
              }
            }}
          >
            Next 4 weeks
          </Button>
          <Button
            colorScheme="blue"
            variant="ghost"
            color={
              useTimestamps
                ? getDateRangeLabel("LAST_4_WEEKS")
                : toDate === lightFormat(startOfToday(), "yyyy-MM-dd") &&
                  fromDate ===
                    lightFormat(subWeeks(startOfToday(), 4), "yyyy-MM-dd")
                ? "blue.800"
                : null
            }
            onClick={() => {
              if (useTimestamps) {
                const d = startOfToday();
                setDateRange(
                  "LAST_4_WEEKS",
                  subWeeks(d, 4).getTime(),
                  d.getTime()
                );
              } else {
                const today = startOfToday();
                setDateRange("", subWeeks(today, 4), today);
              }
            }}
          >
            Last 4 weeks
          </Button>
          <Button
            colorScheme="blue"
            variant="ghost"
            color={
              useTimestamps
                ? getDateRangeLabel("LAST_3_MONTHS")
                : toDate === lightFormat(startOfToday(), "yyyy-MM-dd") &&
                  fromDate ===
                    lightFormat(subMonths(startOfToday(), 3), "yyyy-MM-dd")
                ? "blue.800"
                : null
            }
            onClick={() => {
              if (useTimestamps) {
                const m = startOfMonth(new Date());
                setDateRange(
                  "LAST_3_MONTHS",
                  subMonths(m, 3).getTime(),
                  m.getTime()
                );
              } else {
                const today = startOfToday();
                setDateRange("", subMonths(today, 3), today);
              }
            }}
          >
            Last 3 months
          </Button>
          <Button
            colorScheme="blue"
            variant="ghost"
            color={
              useTimestamps
                ? getDateRangeLabel("LAST_6_MONTHS")
                : toDate === lightFormat(startOfToday(), "yyyy-MM-dd") &&
                  fromDate ===
                    lightFormat(subMonths(startOfToday(), 6), "yyyy-MM-dd")
                ? "blue.800"
                : null
            }
            onClick={() => {
              if (useTimestamps) {
                const m = startOfMonth(new Date());
                setDateRange(
                  "LAST_6_MONTHS",
                  subMonths(m, 6).getTime(),
                  m.getTime()
                );
              } else {
                const today = startOfToday();
                setDateRange("", subMonths(today, 6), today);
              }
            }}
          >
            Last 6 months
          </Button>
          <Button
            colorScheme="blue"
            variant="ghost"
            color={
              useTimestamps
                ? getDateRangeLabel("LAST_YEAR")
                : toDate === lightFormat(startOfToday(), "yyyy-MM-dd") &&
                  fromDate ===
                    lightFormat(subYears(startOfToday(), 1), "yyyy-MM-dd")
                ? "blue.800"
                : null
            }
            onClick={() => {
              if (useTimestamps) {
                const m = startOfYear(new Date());
                setDateRange(
                  "LAST_YEAR",
                  subYears(m, 1).getTime(),
                  m.getTime()
                );
              } else {
                const today = startOfToday();
                setDateRange("", subYears(today, 1), today);
              }
            }}
          >
            Last year
          </Button>
        </ButtonGroup>
        <Flex mx="6" my="2" flexWrap="wrap" gap="4">
          <InputGroup size="sm" minW="250px" flex="1" maxW="400px" rounded="md">
            <InputLeftElement fontWeight={"semibold"} mx="5">
              From:
            </InputLeftElement>
            <Input
              type="date"
              id="fromDate"
              size="sm"
              pl="16"
              value={fromDate}
              onChange={(e) => {
                const value = e.target.value;
                setFromDate(value);
                updateDateQuery("fromDate", value);
              }}
            />
          </InputGroup>
          <InputGroup size="sm" minW="250px" flex="1" maxW="400px" rounded="md">
            <InputLeftElement fontWeight={"semibold"} mx="5">
              To:
            </InputLeftElement>
            <Input
              type="date"
              id="toDate"
              size="sm"
              pl="16"
              value={toDate}
              onChange={(e) => {
                const value = e.target.value;
                setToDate(value);
                updateDateQuery("toDate", value);
              }}
            />
          </InputGroup>
          <Flex gap="2" alignItems="center" minW="100px" h="32px">
            <Button
              colorScheme="blue"
              variant="ghost"
              onClick={clearDateRange}
              visibility={
                (
                  useTimestamps
                    ? filters.fromDate && filters.toDate
                    : query.fromDate && query.toDate
                )
                  ? "visible"
                  : "hidden"
              }
              h="32px"
            >
              <CloseButton size="sm" ml="2" color="blue.600" /> Clear
            </Button>
          </Flex>
        </Flex>
      </Box>

      <Box
        mb="6"
        py="2"
        bg="gray.50"
        rounded="xl"
        width="100%"
        overflowX="auto"
        boxShadow="xl"
      >
        <Text
          fontSize="sm"
          fontWeight="semibold"
          color="gray.700"
          px="6"
          py="2"
        >
          Filter By ($) Amount:
        </Text>
        <Flex mx="6" my="2" flexWrap="wrap" gap="4">
          {useTimestamps && (
            <Select
              mr="4"
              minW="200px"
              maxW="250px"
              flex="1"
              placeholder="-"
              id="amountType"
              size="sm"
              value={amountType || ""}
              onChange={(e) => {
                const value = e.target.value;
                setAmountType(value);
                setQuery((prevQuery) => ({
                  ...prevQuery,
                  filters: {
                    ...(prevQuery.filters || {}),
                    amountType: value,
                    fromAmount: value
                      ? (prevQuery.filters || {}).fromAmount || ""
                      : "",
                    toAmount: value ? (prevQuery.filters || {}).toAmount : "",
                  },
                }));
              }}
            >
              <option value="overallOutstandingBalance">
                Overall Outstanding Balance
              </option>
              <option value="patientOutstandingBalance">
                Patient Outstanding Balance
              </option>
              <option value="tppOutstandingBalance">
                TPP Outstanding Balance
              </option>
              <option value="overallCostPaid">Overall Cost Paid</option>
              <option value="patientCostPaid">Patient Cost Paid</option>
              <option value="tppCostPaid">TPP Cost Paid</option>
              <option value="overallServiceCost">Overall Service Cost</option>
              <option value="patientServiceCost">Patient Service Cost</option>
              <option value="tppServiceCost">TPP Service Cost</option>
            </Select>
          )}
          <InputGroup size="sm" minW="180px" flex="1" maxW="300px" rounded="md">
            <InputLeftElement
              fontWeight={"semibold"}
              mx="2rem"
              whiteSpace="nowrap"
            >
              From: $
            </InputLeftElement>
            <Input
              id="fromAmount"
              type="number"
              size="sm"
              pl="5.25rem"
              placeholder="Min"
              value={fromAmount}
              onChange={(e) => {
                const value = e.target.value;
                setFromAmount(value);
                updateAmountQuery("fromAmount", value);
              }}
            />
          </InputGroup>
          <InputGroup size="sm" minW="180px" flex="1" maxW="300px" rounded="md">
            <InputLeftElement
              fontWeight={"semibold"}
              mx="2rem"
              whiteSpace="nowrap"
            >
              To: $
            </InputLeftElement>
            <Input
              id="toAmount"
              type="number"
              size="sm"
              pl="5.25rem"
              placeholder="Max"
              value={toAmount}
              onChange={(e) => {
                const value = e.target.value;
                setToAmount(value);
                updateAmountQuery("toAmount", value);
              }}
            />
          </InputGroup>
          <Flex gap="2" alignItems="center" minW="120px" h="32px">
            <Button
              colorScheme="blue"
              variant="ghost"
              onClick={clearAmountRange}
              visibility={
                (
                  useTimestamps
                    ? filters.fromAmount || filters.toAmount
                    : query.fromAmount || query.toAmount
                )
                  ? "visible"
                  : "hidden"
              }
              h="32px"
              minW="100px"
            >
              <CloseButton size="sm" ml="2" color="blue.600" /> Clear
            </Button>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
