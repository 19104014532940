import {Flex, Text, Button, useToast, Select} from "@chakra-ui/react";
import {RiDeleteBin2Line} from "react-icons/ri";
import {
  deleteBillingQuery,
  queryFields,
} from "../helpers/Billing/billingQueries";
import NewQuery from "./NewQuery";

export default function BillingQueries({
  dashState,
  billingQueries,
  setBillingQueries,
  setQuery,
  query,
  fields,
  setFields,
  groupings,
  setGroupings,
}) {
  const toast = useToast();

  return (
    <>
      <Flex align={"center"} gap={"2"}>
        <Text
          mr="1"
          fontWeight="semibold"
          color="blue.600"
          fontSize="15px"
          whiteSpace={"nowrap"}
        >
          Custom Queries
        </Text>

        <Select
          size="sm"
          rounded={"md"}
          onChange={(e) => {
            let q = billingQueries.find(
              (query) => query.name === e.target.value
            );
            setQuery(q || {});
            if (q && q.groupings && q.groupings?.length > 0) {
              setGroupings(q.groupings);
            } else {
              setGroupings([]);
            }
            if (q && q.fields) {
              let fields = queryFields(q);
              setFields(fields);
            } else {
              let fields = queryFields({
                fields: [
                  "Status",
                  "Patient Name",
                  "Clinician Name",
                  "Service Name",
                  "Service Date & Time",
                ],
              });
              setFields(fields);
            }
          }}
          value={query?.name || ""}
          placeholder="-"
        >
          {billingQueries.map((e, i) => (
            <option key={i} value={e.name}>
              {e.name}
            </option>
          ))}
        </Select>

        {query?.name && (
          <Button
            onClick={() => {
              if (query?.qid)
                deleteBillingQuery(
                  dashState,
                  toast,
                  query,
                  setBillingQueries,
                  () => {}
                );
            }}
            colorScheme="red"
            variant="outline"
            m="1.5"
            px="6"
            size="sm"
            ml="3"
            leftIcon={<RiDeleteBin2Line />}
          >
            Delete Query
          </Button>
        )}
        <NewQuery
          {...{
            state: dashState,
            toast,
            query,
            setQuery,
            setBillingQueries,
            fields,
            groupings,
            setGroupings,
          }}
        />
      </Flex>
    </>
  );
}
