import {
  Box,
  Flex,
  TableContainer,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import {useState, useRef, useEffect} from "react";
import {IoSearch} from "react-icons/io5";
import {fetchPayments} from "../helpers/Billing/fetchPayments";
import SimplePaymentsList from "./SimplePaymentList";
import Filters from "./Filters";

export default function PaymentsTable({patient, dashState, dispatch}) {
  const toast = useToast();
  const fields = {
    "Patient Name": "pName",
    "Clinician Name": "dName",
    Payer: "payerName",
    "Service Date & Time": "date",
    Amount: "amount",
    "Service Name": "serviceType",
    Description: "description",
  };
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(0);
  const limit = 10;
  let loadingRef = useRef(null);
  let rootRef = useRef(null);

  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState({});

  const isLoadingRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoadingRef.current) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      {
        root: rootRef.current,
        threshold: 1.0,
      }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    fetchPayments(
      dashState,
      page * limit,
      limit,
      setPayments,
      (v) => {
        isLoadingRef.current = v;
      },
      toast,
      patient
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Accordion allowToggle w="100%" maxW="100%" my="4">
        <AccordionItem border="none">
          <Flex gap={4} align="center">
            <h2>
              <AccordionButton
                boxShadow={"md"}
                w={"192px"}
                border="1px solid"
                borderColor={"gray.400"}
                rounded={"md"}
                color={"blue.500"}
                fontWeight={500}
                fontSize={"16px"}
                _expanded={{bg: "blue.500", color: "white"}}
              >
                <Box as="span" flex="1" textAlign="left">
                  Filters
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
          </Flex>
          <AccordionPanel pl="0" maxW="100%" overflow="hidden">
            <Box p="4" gap="8" maxW="100%" overflow="hidden">
              <Filters {...{query, setQuery}} />
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Flex mb="6" align="center">
        <InputGroup maxW="md">
          <InputLeftElement>
            <Icon as={IoSearch} color="gray.500" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search payments..."
            onChange={(e) => setSearchText(e.target.value.toLowerCase())}
            bg="white"
            borderRadius="md"
            border="1px solid"
            borderColor="gray.200"
            boxShadow="md"
          />
        </InputGroup>
      </Flex>

      <TableContainer
        borderRadius="lg"
        boxShadow="xl"
        display="flex"
        flexDirection={"column"}
        className="sbar3"
        height="460px"
        overflowY={"auto"}
        overflowX={"auto"}
        w={{
          base: "calc(100vw - 70px)",
          md: "calc(100vw - 300px)",
        }}
        bg="white"
        border="1px solid"
        borderColor="gray.200"
      >
        <SimplePaymentsList
          {...{
            payments,
            fields,
            query,
            searchText,
            dispatch,
            dashState,
          }}
        />
      </TableContainer>
    </>
  );
}
