import {useLocation} from "react-router-dom";
import Layout from "../components/Layout";
import {useEffect, useReducer, useRef} from "react";
import {init, patientReducer} from "../additional_files/reducer";
import {Box} from "@chakra-ui/react";
import {TransactionsTable} from "../components/Billing/TransactionsTable";
import {verifyUser} from "../authRequests";

export const Billing = () => {
  const {state} = useLocation();
  const [dashState, dispatch] = useReducer(patientReducer, state, init);
  const loadedRef = useRef(null);

  useEffect(function () {
    verifyUser(state ? state.jwt : "", loadedRef);
  }, []);

  return (
    <Layout state={{...dashState}}>
      <Box ref={loadedRef} style={{opacity: 0}}>
        <TransactionsTable
          patient={dashState.patient}
          dashState={dashState}
          dispatch={dispatch}
        />
      </Box>
    </Layout>
  );
};
