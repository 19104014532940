import {Table, Thead, Tbody, Tr, Th, Td, TableCaption} from "@chakra-ui/react";
import {stringifyValue} from "../helpers/stringifyValue";

export default function SimplePaymentsList({
  payments,
  fields,
  query,
  searchText,
}) {
  return (
    <>
      <Table size="md" width="100%" style={{tableLayout: "auto"}}>
        <Thead
          position="sticky"
          zIndex={5}
          top="0"
          bg="gray.100"
          borderBottom="2px"
          borderColor="gray.200"
          boxShadow="sm"
        >
          <Tr>
            {Object.keys(fields).map((field, i) => {
              return (
                <Th
                  fontSize={"13px"}
                  textAlign="center"
                  py={4}
                  color="gray.700"
                  key={i}
                  whiteSpace="nowrap"
                  minW="150px"
                >
                  {field}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {(query.fromDate && query.toDate
            ? payments?.filter((payment) => {
                const paymentDate = new Date(payment.date);
                const fromDate = new Date(query.fromDate);
                const toDate = new Date(query.toDate);

                return paymentDate >= fromDate && paymentDate <= toDate;
              })
            : query.fromAmount || query.toAmount
            ? payments?.filter((payment) => {
                let paymentAmount = 0;
                switch (query.amountType) {
                  case "overallOutstandingBalance":
                    paymentAmount =
                      payment?.tppAmount -
                      payment?.amountPaidByTpp +
                      (payment?.patientAmount - payment?.amountPaidByPatient);
                    break;
                  case "patientOutstandingBalance":
                    paymentAmount =
                      payment?.patientAmount - payment?.amountPaidByPatient;
                    break;
                  case "tppOutstandingBalance":
                    paymentAmount =
                      payment?.tppAmount - payment?.amountPaidByTpp;
                    break;
                  case "overallCostPaid":
                    paymentAmount =
                      payment?.amountPaidByTpp + payment?.amountPaidByPatient;
                    break;
                  case "patientCostPaid":
                    paymentAmount = payment?.amountPaidByPatient;
                    break;
                  case "tppCostPaid":
                    paymentAmount = payment?.amountPaidByTpp;
                    break;
                  case "overallServiceCost":
                    paymentAmount = payment?.tppAmount + payment?.patientAmount;
                    break;
                  case "patientServiceCost":
                    paymentAmount = payment?.patientAmount;
                    break;
                  case "tppServiceCost":
                    paymentAmount = payment?.tppAmount;
                    break;
                  case "amount":
                    paymentAmount = payment?.amount;
                    break;
                  default:
                    paymentAmount = payment?.amount;
                    break;
                }
                const fromAmount = query.fromAmount || 0;
                const toAmount = query.toAmount || Infinity;

                return paymentAmount >= fromAmount && paymentAmount <= toAmount;
              })
            : payments
          )
            ?.filter((payment) =>
              Object.values(fields).some((field) => {
                if (field === "amount") {
                  return String(payment.amount)
                    ?.toLowerCase()
                    ?.includes(searchText.toLowerCase());
                }
                return String(
                  stringifyValue(
                    field
                      .split(".")
                      .reduce((o, k) => (o ? o[k] : null), payment)
                  )
                )
                  ?.toLowerCase()
                  ?.includes(searchText.toLowerCase());
              })
            )
            ?.map((payment, i) => {
              return (
                <Tr key={i} _hover={{bg: "gray.50"}}>
                  {Object.values(fields).map((field, i) => (
                    <Td
                      fontSize={"15px"}
                      key={i}
                      textAlign="center"
                      py={3}
                      px={4}
                      whiteSpace="nowrap"
                    >
                      {field === "date"
                        ? new Date(payment[field]).toLocaleString()
                        : field === "amount"
                        ? `$${parseFloat(payment[field] || 0).toFixed(2)}`
                        : payment[field] ?? "N/A"}
                    </Td>
                  ))}
                </Tr>
              );
            })}
        </Tbody>
        {payments.length === 0 && (
          <TableCaption mb="4">No payments to display.</TableCaption>
        )}
      </Table>
    </>
  );
}
