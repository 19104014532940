import {
  Box,
  Flex,
  TableContainer,
  Icon,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import {useState, useRef, useEffect} from "react";
import {RiFileExcel2Line} from "react-icons/ri";
import {MdClose} from "react-icons/md";
import {fetchTransactions} from "../helpers/Billing/fetchTransaction";
import SimpleTransactionsList from "./SimpleTransactionsList";
import fieldValueFactory from "../helpers/Billing/fieldValueFactory";
import {CustomFields} from "./CustomFields";
import {getExcel} from "../helpers/Billing/getExcel";
import PaymentsTable from "./PaymentsTable";
import Filters from "./Filters";
import BillingQueries from "./BillingQueries";
import {fetchBillingQueries} from "../helpers/Billing/billingQueries";
import GroupingFields from "./GroupingFields";
import TransactionGrouper from "./TransactionGrouper";
export const TransactionsTable = ({patient, dashState, dispatch}) => {
  const toast = useToast();

  const [fields, setFields] = useState({
    Status: fieldValueFactory.Transaction["Status"],
    "Patient Name": fieldValueFactory.Transaction["Patient Name"],
    "Clinician Name": fieldValueFactory.Transaction["Clinician Name"],
    "Service Name": fieldValueFactory.Transaction["Service Name"],
    "Service Date & Time": fieldValueFactory.Transaction["Service Date & Time"],
  });

  const [groupings, setGroupings] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [applyPayment, setApplyPayment] = useState({amount: 0, from: ""});
  const [page, setPage] = useState(0);
  const limit = 250;
  let loadingRef = useRef(null);
  let rootRef = useRef(null);

  const [allowSelection, setAllowSelection] = useState("");
  const [selections, setSelections] = useState(new Set());
  const [paymentType] = useState("total");

  const isLoadingRef = useRef(null);
  const mirrorTable = useRef([]);

  const [cellSelection, setCellSelection] = useState(new Map());
  const [rowCalcValues, setRowCalcValues] = useState([]);
  const [colCalcValues, setColCalcValues] = useState([]);
  const [query, setQuery] = useState({});
  const [billingQueries, setBillingQueries] = useState([]);
  useEffect(() => {
    fetchBillingQueries(dashState, toast, setBillingQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoadingRef.current) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      {
        root: rootRef.current,
      }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    fetchTransactions(
      dashState,
      page * limit,
      limit,
      setTransactions,
      (v) => {
        isLoadingRef.current = v;
      },
      toast,
      patient
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {dashState.changingLocation && (
        <div className="fixed  inset-0 bg-white/80 z-[99999]  flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-dark"></div>
        </div>
      )}

      <Box
        w="full"
        flex={1}
        display={"flex"}
        flexDirection={"column"}
        overflowY={"hidden"}
        ref={rootRef}
        mb="1"
        p={4}
      >
        <Tabs
          variant="soft-rounded"
          flex={1}
          display={"flex"}
          flexDirection={"column"}
          overflow={"hidden"}
          colorScheme="blue"
        >
          <TabList fontSize={"sm"} gap={5} ml={5} mb={3}>
            <Tab py="1">Bills</Tab>
            <Tab py="1">Payments</Tab>
          </TabList>

          <TabPanels flex={1} w="full" overflow={"hidden"} paddingRight={0}>
            <TabPanel
              display={"flex"}
              flexDirection={"column"}
              overflowX={"hidden"}
              h={"full"}
              padding={0}
            >
              <Accordion allowToggle w="100%" maxW="100%" my="4">
                <AccordionItem border="none">
                  <Flex gap={4}>
                    <h2>
                      <AccordionButton
                        boxShadow={"md"}
                        w={"192px"}
                        border="1px solid"
                        borderColor={"gay.400"}
                        rounded={"md"}
                        color={"blue.500"}
                        fontWeight={500}
                        fontSize={"16px"}
                        _expanded={{bg: "blue.500", color: "white"}}
                      >
                        <Box as="span" flex="1" textAlign="left">
                          Filters
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                  </Flex>
                  <AccordionPanel pl="0" maxW="100%" overflow="hidden">
                    {
                      <Box px="4" gap="8" maxW="100%" overflow="hidden">
                        <Box mb="4">
                          <BillingQueries
                            {...{
                              dashState,
                              setBillingQueries,
                              billingQueries,
                              setQuery,
                              query,
                              fields,
                              setFields,
                              groupings,
                              setGroupings,
                            }}
                          />
                        </Box>
                        <Box mb="4">
                          <GroupingFields
                            {...{
                              groupings,
                              setGroupings,
                              setTransactions,
                              patient,
                              dashState,
                            }}
                          />
                        </Box>
                        <Filters {...{query, setQuery, useTimestamps: true}} />
                      </Box>
                    }
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>

              {!(groupings.length > 0) && (
                <Flex mb="2" align={"center"}>
                  <CustomFields {...{setFields, fields}} />
                  <Box ml="4">
                    {allowSelection === "cancel" && (
                      <Icon
                        as={MdClose}
                        color={"gray.500"}
                        _hover={{color: "gray.700"}}
                        w={6}
                        h={6}
                        cursor={"pointer"}
                        onClick={() => {
                          setApplyPayment({amount: 0, from: ""});
                          setAllowSelection("");
                          setSelections(new Set());
                        }}
                      />
                    )}
                  </Box>
                  {mirrorTable.current?.length > 0 && (
                    <Button
                      ml="4"
                      leftIcon={<RiFileExcel2Line />}
                      onClick={() => {
                        let location = dashState.locations.find(
                          (loc) => (loc.lid = dashState.selectedLocation)
                        );
                        console.log(mirrorTable.current);
                        getExcel({
                          state: dashState,
                          table: mirrorTable.current,
                          location,
                          toast,
                        });
                      }}
                      colorScheme="teal"
                      variant="solid"
                    >
                      Export to excel
                    </Button>
                  )}
                </Flex>
              )}
              {groupings.length > 0 ? (
                <TransactionGrouper
                  transactions={transactions}
                  groupings={groupings}
                  fields={fields}
                  setFields={setFields}
                  dashState={dashState}
                />
              ) : (
                <TableContainer
                  borderRadius="lg"
                  boxShadow="xl"
                  display="flex"
                  flexDirection={"column"}
                  className="sbar3"
                  height="460px"
                  overflowY={"auto"}
                  overflowX={"auto"}
                  w={{
                    base: "calc(100vw - 70px)",
                    md: "calc(100vw - 300px)",
                  }}
                  bg="white"
                  border="1px solid"
                  borderColor="gray.200"
                  mt={4}
                >
                  <SimpleTransactionsList
                    {...{
                      paymentType,
                      transactions,
                      fields,
                      query: query.filters || {},
                      searchText: "",
                      dispatch,
                      dashState,
                      allowSelection,
                      setAllowSelection,
                      selections,
                      setSelections,
                      applyPayment,
                      mirrorTable,
                      cellSelection,
                      setCellSelection,
                      rowCalcValues,
                      setRowCalcValues,
                      colCalcValues,
                      setColCalcValues,
                    }}
                  />
                  <div ref={loadingRef} style={{height: "10px"}} />
                </TableContainer>
              )}
            </TabPanel>
            <TabPanel
              display={"flex"}
              flexDirection={"column"}
              overflowX={"hidden"}
              h={"full"}
              padding={0}
            >
              <PaymentsTable
                patient={patient}
                dashState={dashState}
                dispatch={dispatch}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};
