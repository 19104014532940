import axios from "axios";

const url = window.location.href.includes("localhost")
  ? "http://localhost:3000"
  : "https://blh-server-2001610adcd9.herokuapp.com";

export function jwtDecode(t) {
  try {
    return JSON.parse(window.atob(t.split(".")[1]));
  } catch {
    window.location.href = "../login";
  }
}

export function crudStorage(
  state,
  type,
  fileName,
  orgShareName,
  directoryName,
  subdirectoryName
) {
  let config = {
    method: "GET",
    url: `${url}/crudStorage`,
    headers: {
      Authorization: "Bearer " + state.jwt,
    },
    params: {
      s3BucketName: state.organization?.s3BucketName,
      id: jwtDecode(state.jwt).id,
      type: type,
      fileName: fileName,
      orgShareName: orgShareName,
      directoryName: directoryName,
      subdirectoryName: subdirectoryName,
    },
  };
  return axios(config);
}

export function crudStorageUpload(
  state,
  selectedFileUpload,
  orgShareName,
  directoryName
) {
  const formData = new FormData();
  formData.append("file", selectedFileUpload);
  return axios({
    method: "POST",
    url: `${url}/crudStorageUpload`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + state.jwt,
    },
    data: formData,
    params: {
      s3BucketName: state.organization?.s3BucketName,
      id: jwtDecode(state.jwt).id,
      orgShareName: orgShareName,
      directoryName: directoryName,
      s3BucketName: state.organization?.s3BucketName,
    },
  });
}

export function uploadFile(
  state,
  pid,
  selectedFile,
  setMessage,
  setSelectedFile,
  setFiles
) {
  const formData = new FormData();
  formData.append("file", selectedFile);
  let configFile = {
    method: "POST",
    url: `${url}/uploadFile`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + state.jwt,
    },
    data: formData,
    params: {
      id: jwtDecode(state.jwt).id,
      pid: pid,
    },
  };
  axios(configFile)
    .then((res) => {
      setMessage("File upload to patient data drive successful!");
      console.log({res});
      setSelectedFile(null);
      viewFiles(state, pid, setFiles);
    })
    .catch(function (error) {
      setMessage("File upload to patient data drive failed!");
      setSelectedFile(null);
      console.log(error);
    });
}

export function viewFiles(state, pid, setFiles) {
  let configFile = {
    method: "GET",
    url: `${url}/viewFiles`,
    headers: {
      Authorization: "Bearer " + state.jwt,
    },
    params: {
      id: jwtDecode(state.jwt).id,
      pid: pid,
    },
  };
  axios(configFile)
    .then((res) => {
      setFiles(res.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function getFileUrl(state, orgShareName, fileName, directory) {
  let configFile = {
    method: "GET",
    // url: "https://webserver.brightlight.ai/getUrl",
    url: url + "/getFileUrl",
    headers: {
      Authorization: "Bearer " + state.jwt,
    },
    params: {
      id: jwtDecode(state.jwt).id,
      fileName: fileName,
      directoryName: directory,
      orgShareName,
    },
  };
  return axios(configFile);
  // .then((res) => {
  //   window.open(res?.data?.downloadUrl);
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });
}

export function deleteFile(state, fileName, pid, setMessage, setFiles) {
  let configFile = {
    method: "GET",
    url: `${url}/deleteFile`,
    headers: {
      Authorization: "Bearer " + state.jwt,
    },
    params: {
      id: jwtDecode(state.jwt).id,
      key: fileName,
    },
  };
  axios(configFile)
    .then((res) => {
      setMessage("File deletion from patient data drive successful!");
      viewFiles(state, pid, setFiles);
    })
    .catch(function (error) {
      console.log(error);
    });
}
